/* eslint-disable import/no-unresolved */
import { MIDDLEWARE_URL } from '@/lib/constants';

export const getShow = async (slug: string) => {
  if (!slug) return null;
  try {
    const resp = await fetch(`${MIDDLEWARE_URL}/middleware/v4/getShowDataWeb?slug=${slug}`);
    if (!resp.ok) {
      throw new Error(`Error fetching show data for show: ${slug}`);
    }
    const data = resp.json();
    if (!data) {
      throw new Error(`Error parsing show data for show: ${slug}`);
    }
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const getShowEpisodes = async (slug: string, planCode: string, seasonId: string) => {
  if (!slug) return null;
  try {
    const resp = await fetch(
      `${MIDDLEWARE_URL}/middleware/v4/getShowEpisodesWeb?slug=${slug}&membershipPlan=${planCode}&seasonId=${seasonId}`,
    );
    if (!resp.ok) {
      throw new Error(`Error fetching show data for show: ${slug}`);
    }
    const data = resp.json();
    if (!data) {
      throw new Error(`Error parsing show data for show: ${slug}`);
    }
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getShowPaginatedEpisodes = async (paginationURL: string) => {
  if (!paginationURL) return null;
  try {
    const resp = await fetch(MIDDLEWARE_URL + paginationURL);
    if (!resp.ok) {
      throw new Error(`Error fetching episode data for url: ${paginationURL}`);
    }
    const data = resp.json();
    if (!data) {
      throw new Error(`Error parsing show data for show: ${paginationURL}`);
    }
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
