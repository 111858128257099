/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import * as React from 'react';

import cn from 'classnames';

import { ButtonLink, Heading, Icon, Superimposed } from '@/components/design-system';
import { MediaHero } from '@/components/shared/MediaHero';
import { Text } from '@/components/ui';
import { useAuth0 } from '@/context/auth';
import mailbagEmails from '@/data/mailbag.json';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { PRAGERU_DONATE_URL, SITE_URL } from '@/lib/constants';
import { getUtmCookieData } from '@/lib/utm_cookie';
import type { Show, Episode } from '@/types/middleware';

import s from './ShowHeader.module.css';

export interface IShowHeader {
  show: Show;
  latestEpisode?: Episode | null;
  isPragerUShow?: boolean;
}

export const ShowHeader = React.memo<IShowHeader>(({ show, latestEpisode, isPragerUShow }) => {
  const { subscriber, loading } = useAuth0();

  const mailbags = mailbagEmails.filter((mailbagEmail) => mailbagEmail.show === show.title);
  const mailbag = mailbags[0];

  const canAccessMailbag =
    !loading && subscriber && subscriber.recurlyPlan !== 'READER' && subscriber.recurlyPlan !== 'FREE';

  const showMailbag = mailbags.length === 1 && canAccessMailbag;

  const abTestData = getABTestAnalyticData();
  const utmCookieData = getUtmCookieData();

  return (
    <MediaHero
      alignItems='center'
      backgroundImage={show.backgroundImage ?? ''}
      className={cn({ [s.mediaHero__mailbag]: showMailbag })}
      logo={show.logoImage ?? ''}
      title={show.title}
      withGradient={false}
    >
      <Text className={s.description}>{show.description}</Text>
      {latestEpisode && !isPragerUShow && (
        <div className={s.btnGroup}>
          <ButtonLink className={s.btn} href={`/episode/${latestEpisode.slug}`}>
            Latest Episode
          </ButtonLink>
          {/* {show.name === 'Candace' && (
            <ButtonLink className={s.btn} href='/shop/products/candace-show-live-taping' variant='secondary'>
              Get Tickets
            </ButtonLink>
          )} */}
        </div>
      )}

      {isPragerUShow && (
        <ButtonLink
          href={PRAGERU_DONATE_URL}
          onClick={() =>
            window.__DW_Next_Bridge?.Analytics.logEvent('CTA Clicked', {
              ...abTestData,
              ...utmCookieData,
              type: 'button',
              text: 'Donate to PragerU',
              destination: PRAGERU_DONATE_URL,
              location: 'Show Banner',
              page_url: window.location.href,
            })
          }
          variant='secondary'
        >
          Donate to PragerU
        </ButtonLink>
      )}

      {/* Mailbag Start */}
      {showMailbag && (
        <Superimposed
          as='a'
          className={s.mailbagBtn}
          href={`mailto:${mailbag.address}?subject=${mailbag.show} - Mailbag`}
          onClick={() =>
            window.__DW_Next_Bridge?.Analytics.logEvent('CTA Clicked', {
              ...abTestData,
              ...utmCookieData,
              type: 'button',
              text: 'Mailbag',
              destination: `mailto:${mailbag.address}`,
              location: 'Show Banner',
              page_url: `${SITE_URL}/show/${show.slug}`,
            })
          }
          target='_blank'
        >
          <div className={s.iconWrap}>
            <Icon name='PAPER_AIRPLANE' size={20} />
          </div>
          <div>
            <Heading as='div' className={s.heading} variant='100'>
              Mailbag
            </Heading>
            <Text spacing='none' variant='bodySmall'>
              Send {mailbag.firstName} a question
            </Text>
          </div>
        </Superimposed>
      )}

      {/* Mailbag End */}
    </MediaHero>
  );
});

ShowHeader.displayName = 'ShowHeader';
