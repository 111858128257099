/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { useState, useEffect, useRef, useCallback } from 'react';

import { GetStaticPaths, GetStaticProps } from 'next';
import Head from 'next/head';
import { ArticleJsonLd } from 'next-seo';
import Link from 'next/link';
import { buildURL } from 'react-imgix';

import BreadCrumbs from '@/components/breadcrumbs/Breadcrumbs';
import { Container, Hr, Heading, Select, SkeletonLoader, SkeletonLoaderType } from '@/components/design-system';
import EpisodeTeaserInline from '@/components/episodes/EpisodeTeaserInline';
import { ShowHeader } from '@/components/shows/ShowHeader';
import { useAuth0 } from '@/context/auth';
import { appApi } from '@/graphql/app';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { APP_STORE_ID, SITE_URL } from '@/lib/constants';
import { getUtmCookieData } from '@/lib/utm_cookie';
import { stripHtml } from '@/utils/helpers';

// middleware API functions
import { getShow, getShowEpisodes, getShowPaginatedEpisodes } from '@/request/middleware-api';
import { ShowData, ShowEpisode, Episode } from '@/types/middleware';

interface ShowPageProps {
  showData: ShowData;
}

const ShowPage: React.FC<ShowPageProps> = ({ showData }) => {
  const { subscriber, loading } = useAuth0();
  const [episodes, setEpisodes] = useState<ShowEpisode[]>([]);
  const [loadingEpisodes, setLoadingEpisodes] = useState<boolean>(true);
  const [paginationURL, setPaginationURL] = useState<string>('');
  const [seasonId, setSeasonId] = React.useState(showData?.selectedSeason?.id);
  const firstLoad = useRef<boolean>(true);
  const [latestEpisode, setLatestEpisode] = useState<Episode | null>(null);
  React.useEffect(() => {
    if (!showData) return;
    const abTestData = getABTestAnalyticData();
    const utmCookieData = getUtmCookieData();

    window.__DW_Next_Bridge?.Analytics.logEvent('Segment Page', {
      ...abTestData,
      ...utmCookieData,
      title: showData?.show?.title,
    });
  }, [showData]);

  const description = showData?.show?.description ? stripHtml(showData?.show?.description) : '';
  const metaImageUrl = showData?.show?.images?.thumbnail?.land
    ? buildURL(showData?.show?.images?.thumbnail?.land, { w: 800, h: 600 })
    : '';

  const datePublished = showData?.show?.latestEpisode ? showData?.show?.latestEpisode?.publishedAt : '';
  const absUrl = `${SITE_URL}/show/${showData?.show?.slug}`;

  const isPragerUShow = showData?.show?.latestEpisode?.parentTitle === 'prageru';

  const fetchNextEpisodes = useCallback(async () => {
    if (!paginationURL) return;

    setLoadingEpisodes(true);
    try {
      const data = await getShowPaginatedEpisodes(paginationURL);
      if (data) {
        setEpisodes((prev) => {
          const showEpisodes = data.componentItems;
          let dataCopy = [...prev];
          if (Array.isArray(showEpisodes)) {
            dataCopy = [...prev, ...showEpisodes];
          }
          return dataCopy;
        });
        setPaginationURL(data.nextPageUrl); // Update the pagination URL
      }
    } catch (error) {
      console.error('Error fetching episodes:', error);
    } finally {
      setLoadingEpisodes(false);
    }
  }, [paginationURL]);

  useEffect(() => {
    if (loading || !showData.show) return;
    const getData = async () => {
      setEpisodes([]);
      setLoadingEpisodes(true);
      const data = await getShowEpisodes(showData.show.slug, subscriber?.effectivePlan ?? '', seasonId ?? '');
      if (!data) {
        setLoadingEpisodes(false);
        return;
      }
      setEpisodes(() => {
        const showEpisodes = data.componentItems;
        if (Array.isArray(showEpisodes)) {
          return [...showEpisodes];
        } else {
          return [];
        }
      });
      setPaginationURL(data.nextPageUrl);
      if (firstLoad.current === true) {
        setLatestEpisode(data.componentItems[0].showEpisode);
        firstLoad.current = false;
      }
      setLoadingEpisodes(false);
    };

    getData();
  }, [subscriber, loading, seasonId, showData]);

  const ref = useRef<HTMLDivElement | null>(null);

  // Intersection Observer
  useEffect(() => {
    if (!ref.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && paginationURL && !loadingEpisodes) {
          fetchNextEpisodes();
        }
      },
      {
        root: null, // default is the viewport
        rootMargin: '0px 0px 100px 0px', // Trigger slightly before reaching the bottom
        threshold: 0, // Trigger when any part is visible
      },
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, paginationURL, loadingEpisodes, fetchNextEpisodes]);

  return (
    <main style={{ backgroundColor: '#000' }}>
      <Head>
        <meta content='Show' name='parsely-section' />
        <meta content='' name='parsely-tags' />
        <meta content={absUrl} name='parsely-link' />
        {metaImageUrl && <meta content={metaImageUrl} name='parsely-image-url' />}
        <title>{showData?.show?.title ?? ''}</title>
        <link href={absUrl} rel='canonical' />
        <meta content='website' property='og:type' />
        <meta content={description} name='description' />
        <meta content={showData?.show?.title ?? ''} property='og:title' />
        <meta content={description} property='og:description' />
        {metaImageUrl && <meta content={metaImageUrl} property='og:image' />}
        <meta content={absUrl} property='og:url' />
        <meta content={showData?.show?.title ?? ''} property='twitter:title' />
        <meta content={description} property='twitter:description' />
        <meta content='summary_large_image' name='twitter:card' />
        {metaImageUrl && <meta content={metaImageUrl} name='twitter:image' />}
        <meta content={`app-id=${APP_STORE_ID}, app-argument=${absUrl}`} name='apple-itunes-app' />
        <meta content={absUrl} property='al:ios:url' />
        <meta content={absUrl} property='al:android:url' />
        <meta content={absUrl} name='twitter:app:url:iphone' />
        <meta content={absUrl} name='twitter:app:url:ipad' />
        <meta content={absUrl} name='twitter:app:url:googleplay' />
      </Head>
      <ArticleJsonLd
        authorName={showData?.show?.title}
        datePublished={datePublished}
        description={description}
        images={[metaImageUrl]}
        publisherLogo={`${SITE_URL}/images/logo.png`}
        publisherName='The Daily Wire'
        title={showData?.show?.title}
        url={absUrl}
      />
      <ShowHeader isPragerUShow={isPragerUShow} latestEpisode={latestEpisode} show={showData.show} />
      <Container css={{ paddingBottom: '3rem' }} variant='lg'>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '24px',
          }}
        >
          <Heading css={{ color: '#fff' }} variant='600'>
            Episodes
          </Heading>
          {seasonId && showData?.show?.seasons && (
            <Select
              css={(theme) => ({
                backgroundColor: 'rgba(255, 255, 255, 0.075)',
                overflow: 'hidden',
                width: '140px',
                [theme.mq.phablet]: {
                  width: '220px',
                },
              })}
              name='Season Select'
              onChange={(event) => setSeasonId(event.currentTarget.value)}
              value={seasonId}
            >
              {showData?.show.seasons
                .sort((a, b) => Number(b.name) - Number(a.name))
                .map((season) => (
                  <option key={`${showData?.show?.id}-${season?.id}`} value={season?.id}>
                    {`${season?.name}`}
                  </option>
                ))}
            </Select>
          )}
        </div>
        <Hr
          css={(theme) => ({
            display: 'none',
            marginBottom: '32px',
            marginTop: '0px',

            [theme.mq.phablet]: {
              display: 'block',
            },
          })}
        />

        {Array.isArray(episodes) && episodes.length > 0 && (
          <div>
            {episodes.map((episode) => {
              return (
                <EpisodeTeaserInline
                  badgeText={episode.badgeText}
                  episode={episode.showEpisode}
                  key={episode.showEpisode.id}
                />
              );
            })}
          </div>
        )}

        {loadingEpisodes ? (
          <SkeletonLoader count={10} type={SkeletonLoaderType.episode} />
        ) : (
          <div ref={ref} style={{ width: '100%', height: '2px' }} />
        )}
      </Container>

      <BreadCrumbs
        links={[<Link href='/'>DailyWire+</Link>, <Link href='/watch'>Watch</Link>, <>{showData?.show?.title}</>]}
      />
    </main>
  );
};

export default ShowPage;

export const getStaticProps: GetStaticProps = async (context) => {
  const slug = context.params?.slug as string;
  const showData = await getShow(slug);
  if (!showData) {
    return {
      notFound: true,
      revalidate: 600,
    };
  }
  return {
    props: { showData },
    revalidate: 600, // 10 mins (in seconds)
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  const { shows } = await appApi.getShowSlugs();
  const paths = shows.map((show) => {
    const slug = show?.slug as string;
    return { params: { slug } };
  });
  return { paths, fallback: 'blocking' };
};
