/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';

import format from 'date-fns/format';
import NextLink from 'next/link';
import { useMedia } from 'react-use';

import { Heading, Text, Paragraph, Icon } from '@/components/design-system';
import { Episode } from '@/types/middleware';
import { isUpcomingDate, truncate } from '@/utils/helpers';
import { breakpoints } from '@/utils/styles';

interface IEpisodeTeaserInlineProps {
  episode: Episode;
  badgeText: string;
}

const EpisodeTeaserInline: React.FC<IEpisodeTeaserInlineProps> = ({ episode, badgeText }) => {
  const isUpcomingEpisode = isUpcomingDate(episode.scheduledAt ? episode.scheduledAt : '');
  const isDesktopViewport = useMedia(`(min-width: ${breakpoints.desktop}px)`);

  return (
    <article css={{ marginBottom: '32px' }}>
      <NextLink href={`/episode/${episode.slug}`}>
        <a>
          <div css={{ display: 'grid', gridTemplateColumns: '1fr 2fr' }}>
            {/* Image */}
            <div
              css={{
                position: 'relative',
                alignSelf: 'start',
                lineHeight: 0,
                border: 'rgba(11, 11, 17, 0.11)',
                aspectRatio: '16/9',
              }}
            >
              {episode.images?.thumbnail ? (
                <img
                  alt={episode?.title ? episode.title : ''}
                  css={(theme) => ({
                    border: '0.5px solid rgba(11, 11, 17, 0.11) !important,',
                    borderRadius: '15px',
                    aspectRatio: '16/9',
                    [theme.mq.desktop]: {
                      borderRadius: '15px',
                    },
                  })}
                  src={episode.images.thumbnail.land ?? '/images/default_logo.jpg'}
                />
              ) : (
                <img
                  alt='default'
                  css={{
                    border: '0.5px solid rgba(11, 11, 17, 0.11) !important,',
                    borderRadius: '15px',
                    aspectRatio: '16/9',
                  }}
                  src='/images/default_logo.jpg'
                />
              )}
            </div>
            {/* Content */}
            <div
              css={(theme) => ({
                marginLeft: '1rem',
                [theme.mq.desktop]: {
                  marginTop: '.5rem',
                },
              })}
            >
              {/* Live Banner */}
              {badgeText && badgeText === 'LIVE' && (
                <Heading
                  css={(theme) => ({
                    color: theme.colors.red,
                    marginBottom: '8px',
                  })}
                  variant='100'
                >
                  &bull; Now Live
                </Heading>
              )}
              {/* Title */}
              <Heading
                as='h3'
                css={(theme) => ({ color: theme.colors.white, fontFamily: 'Libre Franklin' })}
                variant='400'
              >
                {episode.title}
              </Heading>
              {/* Byline */}
              <Text
                as='div'
                css={(theme) => ({
                  marginTop: 8,
                  color: theme.colors.gray40,
                  fontFamily: 'Libre Franklin',
                })}
                variant='100'
              >
                {/* Clock Icon if upcoming */}
                {isUpcomingEpisode && <Icon css={{ color: '#2F78D2', marginRight: '8px' }} name='CLOCK' size={16} />}
                {/* Date */}
                {!isUpcomingEpisode && episode.publishedAt && (
                  <>{format(new Date(episode.publishedAt ? episode.publishedAt : ''), 'MMM d, yyyy')}</>
                )}
                {/* Time if upcoming */}
                {isUpcomingEpisode && episode.scheduledAt && ` at ${format(new Date(episode.scheduledAt), 'h:mma')}`}
              </Text>
              {/* Description */}
              {isDesktopViewport && episode.description && (
                <Paragraph
                  css={(theme) => ({
                    color: theme.colors.gray40,
                    fontFamily: 'Libre Franklin',
                    marginTop: 8,
                    a: {
                      wordBreak: 'break-word',
                    },
                    lineHeight: 1.5,
                  })}
                >
                  {truncate(episode.description, 320)}
                </Paragraph>
              )}
            </div>
          </div>
          {/* Description */}
          {!isDesktopViewport && episode.description && (
            <Paragraph
              css={(theme) => ({
                color: theme.colors.gray40,
                marginTop: 8,
                wordBreak: 'break-word',
              })}
              variant='100'
            >
              {truncate(episode.description, 200)}
            </Paragraph>
          )}
        </a>
      </NextLink>
    </article>
  );
};

export default EpisodeTeaserInline;
